@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
}

.App {
  scroll-behavior: smooth;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

th {
  @apply px-1 sm:px-6 py-3 text-left text-sm font-medium text-gray-500 bg-gray-50 uppercase tracking-wider sticky top-0;
}

td {
  @apply px-6 py-4 text-sm text-gray-900;
}

tr:nth-child(even) {
  background-color: rgba(247, 248, 250, 1);
}

hr {
  size: 8;
  width: 100%;
}
